<template>
  <div :style="`font-family:${font},sans-serif`">
    <div class="row justify-content-around">
      <div class="col-sm-12 col-md-12 card p-1">
        <div class="title head">
          <p class="h2" style="color: #558cef">ส่งคืนทะเบียนทรัพย์สิน</p>
        </div>
        <hr />
        <div class="row">
          <!-- search input -->
          <b-form-group class="col-12 col-md-4">
            <div class="d-flex align-items-center">
              <label class="mr-1 col-3">เลขทะเบียน:</label>
              <b-form-input
                v-model="search_g.equipment_code"
                placeholder="เลขทะเบียน"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>

          <!-- search input -->
          <b-form-group class="col-12 col-md-4">
            <div class="d-flex align-items-center">
              <label class="mr-1 col-3">รหัสทรัพย์สิน:</label>
              <b-form-input
                v-model="search_g.e_number"
                placeholder="รหัสทรัพย์สิน"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>

          <!-- search input -->
          <b-form-group class="col-12 col-md-4">
            <div class="d-flex align-items-center">
              <label class="mr-1 col-3">ฝ่ายงาน/ตำเเหน่ง:</label>
              <b-form-input
                v-model="search_g.dep_name"
                placeholder="ฝ่ายงาน/ตำเเหน่ง"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
          <!-- search input -->
          <b-form-group class="col-12 col-md-4">
            <div class="d-flex align-items-center">
              <label class="mr-1 col-3">รายการ:</label>
              <b-form-input v-model="search_g.e_name" placeholder="รายการ" type="text" class="d-inline-block" />
            </div>
          </b-form-group>
          <div class="row">
            <div class="col-4 col-md-3 mr-1">
              <b-button variant="success" @click="search_input"> ค้นหา </b-button>
            </div>
            <div class="col-6 col-md-3 ml-1">
              <b-button variant="danger"> ยกเลิก </b-button>
            </div>
          </div>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns1"
          :rows="rows1"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm1,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength1,
          }"
          :line-numbers="true"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <!-- <span v-if="props.column.field === 'fullName'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="mx-1" />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span> -->

            <!-- Column: Status -->
            <span v-if="props.column.field === 'task'">
              <b-button variant="danger">
                {{ props.row.task }}
              </b-button>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-button v-b-modal="`modal-1${props.index}`" variant="outline-primary">
                  <feather-icon icon="ArchiveIcon" class="mr-50" />
                </b-button>
              </span>
              <validation-observer ref="observer" v-slot="{ passes }">
                <b-modal
                  :id="`modal-1${props.index}`"
                  title="ส่งคืนทรัพย์สิน"
                  size="xl"
                  ok-title="ตกลง"
                  cancel-title="ยกเลิก"
                  @ok.prevent="passes(getdata(props))"
                  no-close-on-backdrop
                >
                  <b-card-text>
                    <div class="row">
                      <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ทะเบียนส่งคืน:" disabled>
                        <template v-slot:label> ทะเบียนส่งคืน <span class="text-danger"> *</span> </template>
                        <validation-provider #default="{ errors }" rules="required">
                          <b-form-input
                            class="col-12"
                            :state="errors.length > 0 ? false : null"
                            v-model="equipment_code"
                            id="basicInput"
                            placeholder="AUTO"
                          />
                        </validation-provider>
                      </b-form-group>
                      <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="วันที่รับ:">
                        <template v-slot:label> วันที่รับ <span class="text-danger"> *</span> </template>
                        <validation-provider #default="{ errors }" rules="required">
                          <!-- <b-form-datepicker
                            :state="errors.length > 0 ? false : null"
                            placeholder="วันที่รับ"
                            v-model="props.row.req_date"
                            locale="th-TH"
                            id="example-datepicker"
                            disabled
                          /> -->
                          <v-date-picker
                            id="example-datepicker3"
                            label="วันที่รับ:"
                            locale="th-TH"
                            v-model="props.row.req_date"
                            disabled
                            @update:from-page="getHeaderInputDate"
                          >
                            <template v-slot="{ inputValue, inputEvents }">
                              <b-form-input
                                disabled
                                :value="formatDate(inputValue, 'date2')"
                                v-on="inputEvents"
                                :state="errors.length > 0 ? false : null"
                              />
                            </template>
                            <template #header-title>
                              {{ headerInputDate }}
                            </template>
                          </v-date-picker>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ปีงบประมาณ:">
                        <template v-slot:label> ปีงบประมาณ <span class="text-danger"> </span> </template>

                        <b-form-input
                          maxlength="4"
                          id="basicInput"
                          v-model="data.budget_year"
                          placeholder="ปีงบประมาณ"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        />
                      </b-form-group>
                    </div>
                    <b-row>
                      <!-- <b-form-group
                        class="col-md-4"
                        label-cols="3"
                        label-cols-lg="3"
                        label="เลขที่เอกสาร:"
                      >
                        <template v-slot:label>
                          เลขที่เอกสาร <span class="text-danger"> </span>
                        </template>

                        <b-form-input
                          id="basicInput"
                          placeholder="เลขที่เอกสาร"
                          v-model="data.important_number"
                        />
                      </b-form-group> -->
                      <!-- <b-form-group
                        class="col-md-4"
                        label-cols="3"
                        label-cols-lg="3"
                        label="ประเภทหลักฐาน:"
                      >
                        <template v-slot:label>
                          ประเภทหลักฐาน <span class="text-danger"> *</span>
                        </template>
                        <validation-provider #default="{ errors }" rules="required">
                          <v-select
                            placeholder="ประเภทหลักฐาน"
                            label="evidence"
                            :state="errors.length > 0 ? false : null"
                            v-model="selecteds"
                            :options="proofs"
                          />
                        </validation-provider>
                      </b-form-group> -->
                      <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="วันที่ส่งคืน:">
                        <template v-slot:label> วันที่ส่งคืน <span class="text-danger"> *</span> </template>
                        <validation-provider #default="{ errors }" rules="required">
                          <v-date-picker
                            id="example-datepicker3"
                            label="ลงวันที่:"
                            locale="th-TH"
                            v-model="date1"
                            @update:from-page="getHeaderInputDate"
                          >
                            <template v-slot="{ inputValue, inputEvents }">
                              <b-form-input
                                :value="formatDate(inputValue, 'date1')"
                                v-on="inputEvents"
                                :state="errors.length > 0 ? false : null"
                              />
                            </template>
                            <template #header-title>
                              {{ headerInputDate }}
                            </template>
                          </v-date-picker>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="กลุ่มงาน:">
                        <template v-slot:label> กลุ่มงาน <span class="text-danger"> *</span> </template>
                        <v-select
                          placeholder="เลือกกลุ่มงาน"
                          label="work_gid_work_gname"
                          v-model="workG"
                          :options="workGs"
                          @input="work_select($event)"
                        />
                      </b-form-group>
                      <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ฝ่ายงาน:">
                        <template v-slot:label> ฝ่ายงาน/ตำเเหน่ง <span class="text-danger"> *</span> </template>
                        <v-select
                          placeholder="เลือกฝ่ายงาน"
                          label="dep_id_dep_name"
                          v-model="department"
                          :options="departments"
                          @input="fullnameFromWorkgroupAndDepartment"
                        />
                      </b-form-group>
                    </b-row>
                    <b-row>
                      <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ผู้ส่งคืน:">
                        <template v-slot:label> ผู้ส่งคืน <span class="text-danger"> *</span> </template>
                        <validation-provider #default="{ errors }" rules="required">
                          <v-select
                            label="fullname"
                            :state="errors.length > 0 ? false : null"
                            id="basicInput"
                            v-model="data.return_name"
                            :options="alluser_user"
                            placeholder="ผู้ส่งคืน:"
                          />
                        </validation-provider>
                      </b-form-group>

                      <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ผู้ส่งคืน:">
                        <template v-slot:label> เหตุผลที่ส่งคืน <span class="text-danger"> *</span> </template>

                        <validation-provider #default="{ errors }" rules="required">
                          <v-select
                            label="text"
                            :state="errors.length > 0 ? false : null"
                            id="basicInput"
                            v-model="return_reason"
                            :options="return_reasons"
                            placeholder="เลือกเหตุผลที่ส่งคืน:"
                          />
                        </validation-provider>
                      </b-form-group>

                      <b-form-group class="col-md-12" label-cols="3" label-cols-lg="1" label="เหตุผล/ความจำเป็น:">
                        <template v-slot:label> เหตุผล/ความจำเป็น <span class="text-danger"> </span> </template>

                        <b-form-textarea v-model="data.reason" id="basicInput" placeholder="เหตุผล/ความจำเป็น" />
                      </b-form-group>
                    </b-row>
                  </b-card-text>
                  <div>รายการส่งคืนทรัพย์สิน</div>
                  <vue-good-table :columns="columns2" :rows="[props.row]" :line-numbers="true">
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field === 'task'">
                        <b-button variant="danger">
                          {{ props.row.task }}
                        </b-button>
                      </span>
                      <span v-else-if="props.column.field === 'image'">
                        <b-button v-b-modal="`modal-image${props.index}`" @click="get_image(props.row)">
                          ดูรูปภาพ
                        </b-button>
                        <b-modal :id="`modal-image${props.index}`" title="ดูรูปภาพ" ok-only ok-title="ปิด">
                          <b-card-text class="text-center">
                            <div v-if="image_p != null">
                              <swiper
                                ref="mySwiper"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="swiperOptions"
                              >
                                <swiper-slide v-for="image_p in image_p" :key="image_p">
                                  <b-img
                                    width="250%"
                                    height="250%"
                                    src
                                    :onerror="`fetch('${API}files/image/${image_p}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                                    class="mx-1"
                                  />
                                </swiper-slide>
                                <div slot="button-next" class="swiper-button-next" />
                                <div slot="button-prev" class="swiper-button-prev" />
                                <div slot="pagination" class="swiper-pagination" />
                              </swiper>
                            </div>
                            <span v-else>
                              <div class="text-center">ไม่มีรูปภาพ</div>
                            </span>
                          </b-card-text>
                        </b-modal>
                      </span>
                    </template>
                  </vue-good-table>
                </b-modal>
              </validation-observer>
            </span>

            <!-- Column: Common -->
            <!-- <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span> -->
            <!-- basic modal -->
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength1"
                  :options="['5', '10', '15', '25', '50', '100']"
                  class="mx-1"
                  @input="(value) => perPageChanged1({ currentPerPage: value })"
                />

                <span class="text-nowrap">of {{ total1 }} entries</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="total1"
                  :per-page="pageLength1"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => pageChanged1({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
      <div class="col-sm-12 col-md-12 card p-1">
        <div class="title head">
          <p class="h2" style="color: #558cef">รายการประวัติส่งคืนทรัพย์สิน</p>
        </div>
        <hr />
        <div class="row justify-content-end">
          <!-- search input -->
          <b-form-group class="col-12 col-md-6">
            <div class="d-flex align-items-center">
              <label class="mr-1">ค้นหา:</label>
              <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
              <div>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <b-button variant="outline-primary"
                      ><feather-icon icon="ShareIcon" class="mr-50" />Export
                      <feather-icon icon="ChevronDownIcon" class="mr-50"
                    /></b-button>
                  </template>

                  <b-dropdown-item @click="exportExcel">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    <span>Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-form-group>
        </div>
        <!-- table -->
        <b-overlay :show="show" rounded="sm">
          <vue-good-table
            :columns="columns_return"
            :rows="rows_return"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength2,
            }"
            :line-numbers="true"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->
              <span v-if="props.column.field === 'status'" class="text-nowrap">
                <div>
                  <b-button variant="success"> {{ props.row.status }}</b-button>
                </div>
              </span>

              <span v-else-if="props.column.field === 'accept_status'" class="text-nowrap">
                <div v-if="props.row.accept_status === 'ตรวจสอบ'">
                  <b-button variant="success">ตรวจสอบ</b-button>
                </div>
                <div v-else>
                  <b-button variant="danger">ยังไม่ตรวจสอบ</b-button>
                </div>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-button variant="outline-warning" @click="dateField(props)">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                  </b-button>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength2"
                    :options="['5', '10', '15', '25', '50', '100']"
                    class="mx-1"
                    @input="(value) => perPageChanged2({ currentPerPage: value })"
                  />

                  <span class="text-nowrap">of {{ total2 }} entries</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="total2"
                    :per-page="pageLength2"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => pageChanged2({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCardBody,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { BFormDatepicker } from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import { DatePicker } from "v-calendar";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    required,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BFormDatepicker,
    BCardBody,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    DatePicker,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      API: `${API}`,
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      },
      headerInputDate: "",
      date1: new Date(),
      image_p: null,
      alluser: [],
      show: false,
      search_g: {
        equipment_code: "",
        e_number: null,
        dep_name: null,
        e_name: null,
      },

      equipment_code: "",
      pageLength1: 25,
      currentPage1: 1,
      pageLength2: 25,
      currentPage2: 1,
      dir: false,
      columns1: [
        {
          label: "วัน-เดือน-ปี",
          field: "req_date_th",
        },
        {
          label: "เลขทะเบียน",
          field: "equipment_code",
        },
        {
          label: "รหัสทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "SERIAL NUMBER",
          field: "serial_number",
        },
        {
          label: "รายการ",
          field: "e_name",
        },
        {
          label: "ผู้เบิก",
          field: "req_name",
        },
        {
          label: "ฝ่ายงาน/ตำแหน่ง",
          field: "dep_name",
        },

        {
          label: "สถานะ",
          field: "task",
        },
        {
          label: "ส่งคืน",
          field: "action",
        },
      ],
      columns2: [
        {
          label: "วัน-เดือน-ปี",
          field: "req_date_th",
        },
        {
          label: "เลขทะเบียน",
          field: "equipment_code",
        },
        {
          label: "รหัสทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "SERIAL NUMBER",
          field: "serial_number",
        },
        {
          label: "รายการ",
          field: "e_name",
        },
        {
          label: "ผู้เบิก",
          field: "req_name",
        },
        {
          label: "ฝ่ายงาน/ตำแหน่ง",
          field: "dep_name",
        },

        {
          label: "สถานะ",
          field: "task",
        },
        {
          label: "รูปภาพ",
          field: "image",
        },
      ],
      rows1: [],
      total1: "",
      total2: "",
      reason: [],

      searchTerm: "",
      columns_return: [
        {
          label: "วัน-เดือน-ปี(วันที่รับ)",
          field: "req_date_th",
        },
        {
          label: "วัน-เดือน-ปี(วันที่คืน)",
          field: "return_date_th",
        },
        {
          label: "เลขทะเบียน",
          field: "equipment_code",
        },

        {
          label: "กลุ่มงาน",
          field: "work_gname",
        },
        {
          label: "ผู้คืน",
          field: "return_name",
        },
        {
          label: "ตรวจสอบ",
          field: "accept_status",
        },

        {
          label: "สถานะ",
          field: "status",
        },
        {
          label: "ดูรายการ",
          field: "action",
        },
      ],
      rows_return: [],
      searchTerm1: "",
      searchTerm2: "",
      searchTerm3: "",
      reason: [],
      reasons: [
        { value: "ชำรุด", text: "ชำรุด" },
        { value: "เสื่อมสภาพ", text: "เสื่อมสภาพ" },
        { value: "ใช้การไม่ได้", text: "ใช้การไม่ได้" },
        { value: "อื่นๆ", text: "อื่นๆ" },
      ],
      return_reason: "",
      return_reasons: [
        { value: "ชำรุด", text: "ชำรุด" },
        { value: "เสื่อมสภาพการใช้งาน", text: "เสื่อมสภาพการใช้งาน" },
        { value: "ใช้การไม่ได้", text: "ใช้การไม่ได้" },
        { value: "อื่นๆ", text: "อื่นๆ" },
      ],
      selecteds: null,
      proofs: [],
      workGuser: { title: "เลือกกลุ่มงาน" },
      workG: "",
      workGs: [],
      returndepartment: { title: "เลือกฝ่ายงาน" },
      department: null,
      departments: [],
      data: {
        return_date: "",
        budget_year: "",
        important_number: "",
        return_name: "",
        reason: "",
      },
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      user: "",
      status_user: "",
      username_user: "",
      alluser_user: [],
      getlog: "",
      font: "",
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    // this.checkLogout();
    const { usertype, username } = await JSON.parse(localStorage.getItem("username_type"));
    this.status_user = await usertype;
    this.username_user = await username;
    this.user = await username;
    this.material_code();
    this.work();
    this.get_data_table();
    this.s_evidenceTypes();
    this.alluser_table();
    this.getdata_table();
    this.auto_();
  },
  methods: {
    async checkLogout() {
      console.log("ตรวจสอบออกจากระบบ");
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(" User อยู่ในระบบ");
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
    },
    async auto_() {
      console.log(this.data);
      const { work_gid, dep_id, fullname } = await JSON.parse(localStorage.getItem("username_type"));

      const { access_token } = await this.access_token();
      const url_herd = `${API}headWorkgroup?work_gid=${work_gid}`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_heard = await axios.get(url_herd, header);

      // const dep_id_dep_name = `${API}departments?dep_name=${dep_id}`;
      // const res_dep_id_dep_name = await axios.get(dep_id_dep_name, header);
      // console.log(res_heard.data.message.data);
      // console.log(res_dep_id_dep_name.data.message.data);

      const aaa = [...res_heard.data.message.data];
      console.log(aaa);
      const [user] = res_heard.data.message.data.map((el) => {
        return {
          ...el,
          work_gid: `${el.workgroup.split("-")[0].replace(" ", "")}`,
          work_gid_work_gname: `${el.workgroup}`,
          dep_id: `${el.depname.split("-")[0].replace(" ", "")}`,
          dep_id_dep_name: `${el.depname}`,
          fullname: `${el.title}${el.firstname} ${el.lastname}`,
        };
      });
      console.log(user);
      this.workG = user;
      this.department = user;
      this.req_name = user;
      this.req_name_ = fullname;
      this.data.return_name = fullname;
    },
    getHeaderInputDate(events) {
      switch (events.month) {
        case 1:
          this.headerInputDate = "มกราคม " + (events.year + 543);
          break;
        case 2:
          this.headerInputDate = "กุมภาพันธ์ " + (events.year + 543);
          break;
        case 3:
          this.headerInputDate = "มีนาคม " + (events.year + 543);
          break;
        case 4:
          this.headerInputDate = "เมษายน " + (events.year + 543);
          break;
        case 5:
          this.headerInputDate = "พฤษภาคม " + (events.year + 543);
          break;
        case 6:
          this.headerInputDate = "มิถุนายน " + (events.year + 543);
          break;
        case 7:
          this.headerInputDate = "กรกฎาคม " + (events.year + 543);
          break;
        case 8:
          this.headerInputDate = "สิงหาคม " + (events.year + 543);
          break;
        case 9:
          this.headerInputDate = "กันยายน " + (events.year + 543);
          break;
        case 10:
          this.headerInputDate = "ตุลาคม " + (events.year + 543);
          break;
        case 11:
          this.headerInputDate = "พฤศจิกายน " + (events.year + 543);
          break;
        case 12:
          this.headerInputDate = "ธันวาคม " + (events.year + 543);
          break;
        default:
          this.headerInputDate = "ข้อมูลผิดพลาด";
          break;
      }
    },
    formatDate(date, val) {
      let formatDate = "";
      let dete_val = "";
      if (date != null) {
        const _y = date.split("/");
        const _date = parseInt(_y[2]) + 543;
        formatDate = `${_y[0]}/${_y[1]}/${_date}`;
        dete_val = _y[2] + "-" + _y[1] + "-" + _y[0];
        switch (val) {
          case "date1":
            this.data.return_date = dete_val;
            break;
        }
      } else {
        formatDate = "-";
      }
      if (formatDate.toString() == "/undefined/NaN") {
        formatDate = "";
      }
      return formatDate;
    },
    async getdata_table() {
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      const url1 = `${API}filterTaskByUser?username=${this.username_user}&event_name=เบิกทรัพย์สิน`;
      const res1 = await axios.get(url1, head);
      const data_query = res1.data.message.data.map((el) => {
        return {
          ...el,
          equipment_code: el.task_code,
        };
      });
      console.log(data_query);

      const url = `${API}returns?_page=1&_limit=100&_sort=-1`;
      const res = await axios.get(url, head);
      const main_data = res.data.message.data;
      console.log(main_data);

      if (this.status_user == "ผู้ใช้ทั่วไป") {
        // console.log(main_data);
        // console.log(data_query);
        const data_querys = data_query.map((el) => el.equipment_code);
        const data_a = main_data.filter((el) => data_querys.includes(el.equipment_code));
        // console.log(data_a);
        this.rows1 = data_a.map((el) => {
          return {
            ...el,
            req_date_th: `${parseInt(el.req_date.split("-")[2])} ${this.month[parseInt(el.req_date.split("-")[1])]} ${
              Number(el.req_date.split("-")[0]) + 543
            }`,
          };
        });
        this.total1 = data_a.length;
      } else {
        const res1 = res.data.message.data.map((el) => {
          return {
            ...el,
            req_date_th: `${parseInt(el.req_date.split("-")[2])} ${this.month[parseInt(el.req_date.split("-")[1])]} ${
              Number(el.req_date.split("-")[0]) + 543
            }`,
          };
        });
        this.rows1 = res1;
        this.total1 = res.data.message.total;
      }
    },
    handlePagechange1(event) {
      this.currentPage = event;
      this.getdata_table() || this.search_input();
    },
    handleChangePage1(event) {
      this.pageLength = event;
      this.getdata_table() || this.search_input();
    },
    pageChanged2(event) {
      this.currentPage2 = event.currentPage;
      this.getdata_table() || this.search_input();
    },
    perPageChanged2(event) {
      this.pageLength2 = event.currentPerPage;
      this.getdata_table() || this.search_input();
    },
    async get_image(value) {
      console.log(value);
      this.image_p = null;
      // console.log(value.e_number);
      const { access_token } = await this.access_token();
      this.token = access_token;
      const api = `${API}equipmentDetail?e_number=${value.e_number}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(api, head);
      console.log(res);
      // console.log(typeof res.data.message.data)
      // console.log(res.data.message.data[0].e_image);
      if (res.data.message.data[0].e_image != null || res.data.message.data[0].e_image != "") {
        this.image_p = res.data.message.data[0].e_image;
        // console.log(JSON.parse(this.image_p));
        this.image_p = JSON.parse(this.image_p);
        this.image_p = this.image_p;
        // console.log(this.image_p);
      } else {
        this.image_p = null;
      }
    },
    async fullnameFromWorkgroupAndDepartment() {
      const { access_token } = await this.access_token();
      const url = `${API}fullnameFromWorkgroupAndDepartment?work_gid=${this.workG.work_gname}&dep_id=${this.department.dep_name}`;
      // console.log(url);
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.alluser_user = res.data.message.data;
    },
    async alluser_table() {
      const { access_token } = await this.access_token();
      const url = `${API}user?_page=1&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res);
      const res_user = res.data.message.data.map((rs) => {
        return {
          ...rs,
          firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
        };
      });
      this.alluser = res_user;
    },
    async s_evidenceTypes() {
      const { access_token } = await this.access_token();
      const url = `${API}evidenceTypes`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.proofs = res.data.message.data;
    },
    async logUser() {
      // console.log(this.user);
      const { access_token } = await this.access_token();
      const url = `${API}addHistoryLogs`;
      const data = {
        username: this.user,
        event_name: `ส่งคืนทะเบียนทรัพย์สิน ${this.getlog} `,
      };
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.post(url, data, head);
      // console.log(res.data.message);
    },
    dateField: async function (props) {
      this.$router.push({
        name: "inreturnkuruplun",
        params: {
          rowData: props.row, // or anything you want
        },
      });
    },
    exportExcel() {
      saveExcel({
        data: this.rows_return,
        fileName: "รายการประวัติส่งคืนทรัพย์สิน",
        columns: [
          {
            title: "รายการประวัติส่งคืนทรัพย์สิน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "วัน-เดือน-ปี(วันที่รับ)",
                field: "req_date_th",
              },
              {
                label: "วัน-เดือน-ปี(วันที่คืน)",
                field: "return_date_th",
              },
              {
                label: "เลขทะเบียน",
                field: "equipment_code",
              },

              {
                label: "ฝ่ายงาน",
                field: "work_gname",
              },
              {
                label: "ผู้คืน",
                field: "return_name",
              },

              {
                label: "สถานะ",
                field: "status",
              },
            ],
          },
        ],
      });
    },
    async work() {
      const { access_token } = await this.access_token();
      const workgroups = `${API}workgroups?_page=1&_limit=100&_sort=1`;
      const header_workgroups = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_workgroups = await axios.get(workgroups, header_workgroups);
      // console.log(res_workgroups.data.message.data);
      const res_amp = res_workgroups.data.message.data.map((element) => {
        return {
          ...element,
          work_gid_work_gname: `${element.work_gid} - ${element.work_gname}`,
        };
      });
      this.workGs = res_amp;
    },
    async work_select(event) {
      // console.log(event);
      const { access_token } = await this.access_token();
      const m_gid = event.work_gid;
      const work_gname = event.work_gname;
      const url = `${API}departments?work_gid=${m_gid}&_limit=100`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      // console.log(res.data.message.data);
      const res_amp = res.data.message.data.map((element) => {
        return {
          ...element,
          dep_id_dep_name: `${element.dep_id} - ${element.dep_name}`,
        };
      });
      this.departments = res_amp;

      console.log(url_herd);
      const header_Workgroup = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_heard = await axios.get(url_herd, header_Workgroup);
      console.log(res_heard);
      const [user] = res_heard.data.message.data.map((el) => {
        return {
          ...el,
          work_gid: `${el.workgroup.split("-")[0].replace(" ", "")}`,
          work_gid_work_gname: `${el.workgroup}`,
          dep_id: `${el.depname.split("-")[0].replace(" ", "")}`,
          dep_id_dep_name: `${el.depname}`,
          fullname: `${el.title}${el.firstname} ${el.lastname}`,
        };
      });
      // this.workG = user;
      this.department = user;
      this.req_name = user;
    },
    async search_input() {
      const { access_token } = await this.access_token();
      const url = `${API}returns?equipment_code=${this.search_g.equipment_code}&e_number=${this.search_g.e_number}&dep_name=${this.search_g.dep_name}&e_name=${this.search_g.e_name}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      // console.log(url);
      const res = await axios.get(url, head);
      const res1 = res.data.message.data.map((el) => {
        return {
          ...el,
          req_date_th: `${parseInt(el.req_date.split("-")[2])} ${this.month[parseInt(el.req_date.split("-")[1])]} ${
            Number(el.req_date.split("-")[0]) + 543
          }`,
        };
      });
      // console.log(res1);
      this.rows1 = res1;
      this.total1 = res.data.message.total;
    },

    async material_code() {
      const { access_token } = await this.access_token();
      const url = `${API}returnsAssetNumber`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const data_res = await axios.get(url, header);
      console.log(data_res);
      const { equipment_code, code_number, budget_year, equipment_code_mix } = data_res.data.message.data[0];
      const materialcode = `${equipment_code}-${code_number.toString().padStart(3, "0")}-${budget_year}`;
      this.equipment_code = equipment_code_mix.replace("-NaN-", "001-");
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
    async getdata(params) {
      // this.checkLogout();
      console.log(params.index);

      console.log(this.return_reason);
      if (!this.return_reason) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "กรุณาเลือก เหตุผลที่ส่งคืน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      const { access_token } = await this.access_token();
      // console.log(params);
      console.log(this.equipment_code);
      const data = {
        equipment_code: this.equipment_code,
        return_date: this.data.return_date,
        budget_year: this.data.budget_year || " ",
        important_number: this.data.important_number || " ",
        evidence_type: " ",
        action_date: this.data.return_date,
        work_gid: this.workG.work_gid,
        dep_id: this.department.dep_id,
        return_name: this.data.return_name.fullname || this.data.return_name,
        reason: this.data.reason,
        equipment_code_requisition: params.formattedRow.equipment_code,
        e_number: params.formattedRow.e_number,
        return_reason: this.data.reason,
        chk_return_reason: this.return_reason.value,
        equipment_code_gen: "true",
      };
      console.log(JSON.stringify(data));

      // console.log(data);
      const url = `${API}returns`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };

      // console.log(url, data, header);
      const data_res = await axios.post(url, data, header);
      console.log(data_res);

      // console.log(data_res.data);
      if (data_res.data.status === 200) {
        this.$bvModal.hide(`modal-1${params.index}`);
        this.getlog = data_res.data.message.equipment_code;
        //this.$bvModal.hide(`modal-1${params.index}`);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "บันทึก",
          showConfirmButton: false,
          timer: 1500,
        });
        this.logUser();
        this.get_data_table();
        this.getdata_table();
        this.material_code();
      } else if (data_res.data.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: data_res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: data_res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();

      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url1 = `${API}filterTaskByUser?username=${this.username_user}&event_name=ส่งคืนทะเบียนทรัพย์สิน`;
      const res1 = await axios.get(url1, header);
      const data1 = res1.data.message.data.map((el) => {
        return {
          ...el,
          equipment_code: el.task_code,
        };
      });

      const url = `${API}returnsList?_sort=-1&_page=${this.currentPage2}&_limit=${this.pageLength2}`;
      const data_res = await axios.get(url, header);
      const data2 = data_res.data.message.data;

      const sum_data = [...data1, ...data2];
      console.log(data_res.data.message);
      // console.log(sum_data);

      if (this.status_user == "ผู้ใช้ทั่วไป") {
        this.show = false;
        console.log(data1);
        console.log(data2);
        const data_querys = data1.map((el) => el.equipment_code);
        const data_a = data2.filter((el) => data_querys.includes(el.equipment_code));
        console.log(data_a);

        this.total2 = data_a.length;
        this.rows_return = data_a.map((element) => {
          return {
            ...element,
            accept_status: element.accept_status === "รับทราบ" ? "ตรวจสอบ" : "ยังไม่ตรวจสอบ",
            req_date_th: `${parseInt(element.req_date.split("-")[2])} ${
              this.month[parseInt(element.req_date.split("-")[1])]
            } ${Number(element.req_date.split("-")[0]) + 543}`,
            return_date_th: `${parseInt(element.return_date.split("-")[2])} ${
              this.month[parseInt(element.return_date.split("-")[1])]
            } ${Number(element.return_date.split("-")[0]) + 543}`,
          };
        });
      } else {
        console.log(data_res.data.message.data);
        this.total2 = data_res.data.message.total;
        this.rows_return = data_res.data.message.data.map((element) => {
          return {
            ...element,
            accept_status: element.accept_status === "รับทราบ" ? "ตรวจสอบ" : "ยังไม่ตรวจสอบ",
            req_date_th: `${parseInt(element.req_date.split("-")[2])} ${
              this.month[parseInt(element.req_date.split("-")[1])]
            } ${Number(element.req_date.split("-")[0]) + 543}`,
            return_date_th: `${parseInt(element.return_date.split("-")[2])} ${
              this.month[parseInt(element.return_date.split("-")[1])]
            } ${Number(element.return_date.split("-")[0]) + 543}`,
          };
        });
        // this.rows_return = data_res.data.message.data;
        this.show = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
